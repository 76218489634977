import React from "react"
import { Link } from "gatsby"
import {
  Row,
  Col,
  Container, 
  Breadcrumb,
  Button,

  Accordion,
  Card
} from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import LuteinComplexMobile from "./../../images/Lutein-Complex-mobile-en.jpg"
import LuteinComplexTablet from "./../../images/Lutein-Complex-tablet-en.jpg"
import LuteinComplexDesktop from "./../../images/Lutein-Complex-desktop-en.jpg";
import LuteinComplexXL from "./../../images/Lutein-Complex-desktop-xl-en.jpg";
import Feature1 from "./../../images/LUTEIN-EYE-LOUPE.jpg"
import Feature2 from "./../../images/LUTEIN-BILBERRY-MARIGOLDS.jpg"
import Feature3 from "./../../images/LUTEIN-EYE-ANATOMY.jpg"
import Feature4 from "./../../images/LICAPS-GUARANTEE.jpg"
import Feature5 from "./../../images/lutein-table-mobile-en.svg"
import Feature5xl from "./../../images/lutein-table-xl-en.svg"


const LuteinPageEn = () => (
  <Layout backButton={true} enSite pageInfo={{ pageName: "lutein-complex-en" }}>
    <Seo title="Lutein Complex Plus - The No. 1 eye care product" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/en">HOME</Link>
            </li>
            <Breadcrumb.Item active>LUTEIN COMPLEX PLUS</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Lutein Complex Plus"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
        <Col xs={6} className="d-none d-lg-block large-heading">
          <h5 className="text-uppercase font-light letter-space-wide ">
            Lutein Complex Plus
          </h5>
          <h1 className="display-4 font-light">
            Number 1<br /> in eye care*
          </h1>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col sm={12} className="d-lg-none">
          <h4 className="text-uppercase mb-3 letter-space-wide">
            Lutein Complex Plus
          </h4>
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Number 1<br /> in eye care*
          </h1>
        </Col>
        <Col sm={12} md={6} className="mb-4">
          <h5 className="subhead font-light">
            Capsules with liquid contents that have incorporated in themselves
            the optimal amounts of lutein, zeaxanthin, bilberry extract and zinc
            - the most beneficial substances to the eyes.
          </h5>
        </Col>
        <Col sm={12} md={6}>
          <Col className="mb-4 p-0">
            <div>
              <a
                href="https://bemore.shop/ena/lutein-complex-envh1002"
                target="blank"
                rel="nofollow"
                className="text-decoration-none"
              >
                <Button
                  variant="primary"
                  className="buy-button mx-auto d-block"
                >
                  Buy online at <strong>BeMore.shop</strong>
                </Button>
              </a>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <p>Available in pharmacies across the country</p>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <ul>
                <li>solvent free</li>
                <li>preservatives free</li>
                <li>gluten free</li>
                <li>sugar free</li>
                <li>GMO-free</li>
              </ul>
            </div>
          </Col>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">A view on eyesight</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6} className="order-md-1">
          <img
            srcSet={Feature1}
            alt="Поглед върху зрението"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            We take care of our eyes only when there is a problem with them, and
            as one of our most important senses, we should not ignore them. Many
            eye problems related to improper diet or advancing age can be
            avoided. To do this, it is necessary to take the right measures
            early.
          </p>
          <p>
            Lutein Complex has been caring for the eyes of thousands of people
            for over 15 years. Its specially selected ingredients have a proven
            beneficial effect on eye health, and innovative technology
            accelerates its absorption by the body many times over.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">The optimal care for your eyes</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6}>
          <img
            srcSet={Feature2}
            alt="The optimal care for your eyes"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6}>
          <p>
            The active ingredients in Lutein Complex are vital for maintaining
            good eye health according to a number of studies.**
          </p>
          <p>
            Lutein and zeaxanthin are the most important antioxidants found in
            human eyes and they neutralize the damages caused by free radicals.
            Lutein and Zeaxanthin are the carotenoids responsible for coloring
            the eye. Zeaxanthin is the component that dominates the very center
            of the macula of the eye, and lutein is found in higher
            concentrations in the retina.
          </p>
          <p>
            Bilberry extract helps strengthen eye capillaries, regenerates
            visual pigments and improves circulatory activity.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">
            Preventive measure and overall solution
          </h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6} className="order-md-1">
          <img
            srcSet={Feature3}
            alt="Preventive measure and overall solution"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            Taking Lutein Complex is a preventive measure for the main visual
            disturbances that appear with the age.
          </p>
          <p>
            A direct link has been established between lutein and zeaxanthin
            intake and the prevention of cataracts and the development of
            age-related macular degeneration. ** Age-related macular
            degeneration causes progressive loss of central vision.
          </p>
          <p>
            Cataracts lead to an increase in the density of the lens, and hence
            to a decrease in its transparency, leading to blindness.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Guarantee for quality and safety</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6}>
          <img
            srcSet={Feature4}
            alt="Guarantee for quality and safety"
            className="w-100 fluid rounded mb-4 "
          />
        </Col>
        <Col md={6}>
          <p>
            Lutein Complex Plus capsules are manufactured at Capsugel's plants
            in Colmar, France, using the patented technology for using liquid
            plant extracts in hard capsules.
          </p>
          <p>
            This technology eliminates the heat treatment of substances, which
            allows to preserve their useful properties and ensures rapid and
            complete absorption by the body. Compared to solid forms such as
            tablets, the ingredients in Lutein Complex Plus capsules are
            released very quickly because they are pre-dissolved. They are
            completely natural and free of solvents, preservatives, gluten,
            sugar and GMOs.
          </p>
          <p>
            Read more in the <Link to="/en/technology">“Technology”</Link>{" "}
            section.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Clinically proven effectiveness</h2>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col>
          <picture>
            <source srcSet={Feature5xl} media="(min-width: 760px)" />
            <source srcSet={Feature5} media="(min-width: 370px)" />
            <img
              srcSet={Feature5}
              alt="Clinically proven effectiveness"
              className="mb-4 mw-100 mx-auto d-block"
            />
          </picture>
        </Col>
      </Row>

      <Row className="mb-2 product-container">
        <Col>
          <h5 className="section-heading">Additional information</h5>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col>
          <Accordion defaultActiveKey="0" className="acc-style">
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                className="pointer"
              >
                Contents
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    <strong>Lutein</strong> 33.0 mg
                  </p>
                  <p>
                    <strong>Zeaxanthin</strong> 8.25 mg
                  </p>
                  <p>
                    <strong>Bilberry extract</strong> (Extr. Vaccinium
                    myrtillus, 25% anthocianidines) 30.0 mg{" "}
                  </p>
                  <p>
                    <strong>Vitamin C</strong> (Ascorbic acid) 66.0 mg
                  </p>
                  <p>
                    <strong>Vitamin E</strong> ( D – Alpha tocopherol natural)
                    16.5 mg α-TE{" "}
                  </p>
                  <p>
                    <strong>Zinc</strong> (Zinc Sulfate) 15.0 mg
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="1"
                className="pointer"
              >
                Intake
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>
                    Take 1 capsule Lutein Complex daily after meals, no less
                    than 3 months, without interruption.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="2"
                className="pointer"
              >
                Contraindications
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>
                    None identified. Not recommended for people allergic to fish
                    products. Lutein Complex Plus is a dietary supplement and
                    does not cause irritation and hypersensitivity, and can be
                    taken for a long period. Lutein Complex Plus can be taken
                    alone or in combination with medication prescribed by your
                    doctor.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="3"
                className="pointer"
              >
                Effects
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p>
                    Lutein Complex is a combination of highly effective
                    antioxidants. Vitamin C, vitamin E and zinc help protect
                    cells from oxidative stress. The increased concentration of
                    bioflavonoids - lutein and zeaxanthin in the eyes, supports
                    their health in fatigue and aging. Bilberry extract
                    supports the eye's function to adapt between light and dark, and also helps maintain visual acuity.
                    Favorably affects the functions of the eyes when exposed to
                    the environment - UV radiation from the sun, artificial
                    lighting and more. Zinc helps maintain normal vision.
                  </p>
                  <p>
                    <a
                      href="http://vitaherb.bg/leaflets/Lutein-Complex-Plus-Quality-Certificate.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Product quality certificate
                    </a>
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
      <Row className="mb-2 product-container">
        <Col>
          <h5 className="section-heading">Sources and studies</h5>
        </Col>
      </Row>
      <Row className="mb-4 product-container">
        <Col className="h6-small">
          <p>
            * Best selling product in category "Vitamins and eye supplements"
            for 2020, according to IQVIA.
          </p>
          <p>
            ** Richer S, Stiles W, Statkute L, et al. Lutein Antioxidant
            Supplementation Trial. Optometry 2004;75:216-230; Seddon JM, Ajani
            UA, Sperduto RD, et al. JAMA 1994; 272:1413-1420;
            <br /> Chasen - Taber L, Willet WC, Seddon JM, et al. Am J Clin Nutr
            1999;70:509-516.
            <br /> Invest. Ophthalmol. Vis. Sci. June 2006 vol. 47 no. 6
            2329-2335 Catharine R. Gale, Nigel F. Hall, David I. W. Phillips and
            Christopher N. Martyn, Lutein and Zeaxanthin Status and Risk of
            Age-Related Macular Degeneration Invest. <br />
            Ophthalmol. Vis. Sci. June 2003 vol. 44 no. 6 2461-2465
            <br /> D M Snodderly, Evidence for protection against age-related
            macular degeneration by carotenoids and antioxidant vitamins, Am J
            Clin Nutr December 1995 vol. 62 no. 6 1448S-1461S
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default LuteinPageEn
